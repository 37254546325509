import React, { useState } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import Why from "./Why";
import Partner from "./Partner";
import bann from "../assets/images/franchise.png";

function Franchise() {
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    mobileNumber: "",
    email: "",
    investment: "",
    state: "",
    city: "",
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState("");

  const handleCaptchaChange = (value) => {
    console.log("Captcha value:", value);
    setCaptchaVerified(!!value); // Set to true if captcha is successfully completed
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const closeModal = () => {
    setShowModal(false);
    setSubmissionMessage("");
  };

  const resetForm = () => {
    setFormData({
      name: "",
      mobileNumber: "",
      email: "",
      investment: "",
      state: "",
      city: "",
    });
    setCaptchaVerified(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://skillhaara.com/api/user/franchise",
        formData
      );
      console.log("Form submitted successfully:", response.data);
      setFormSubmitted(true);
      setSubmissionMessage("Form submitted successfully!");
      setShowModal(true); // Show the modal after successful submission
      setErrorMessage("");

      // Reset the form after successful submission
      resetForm();
    } catch (error) {
      console.error("Error submitting form:", error);
      setErrorMessage("Failed to submit the form. Please try again.");
    }
  };

  return (
    <>
      <div className="Contact-baner-container">
        <img src={bann} alt="" />
      </div>
      <div className="fran-prim">
        <h3 className="fran-main">Empower The Future of EdTech</h3>
        <h1 className="fran-sub">with Skill Haara!</h1>
        <p className="fran-para">
          As industries evolve and technology reshapes the world, the demand for
          future-ready skills is surging like never before. At Skill Haara, we
          lead the charge by offering innovative training in IT Software
          Engineering & Design, Testing, IT Infrastructure Management,
          Electronic Design Automation, Industrial Automation, Data Science,
          Machine Learning, IoT, Artificial Intelligence, Digital Marketing with
          AI tools, Fashion Design, Boutique Business Management, Graphics &
          Animation, Interior Design, and Building Infrastructure Modelling
          (BIM).
        </p>
        <p className="fran-para">
          Are you ready to create a lasting impact in the thriving education
          sector? With Skill Haara’s proven expertise and a commitment to
          excellence, we invite passionate edupreneurs to join our growing
          franchise network. Together, let’s transform careers and build a
          brighter tomorrow!
        </p>
        </div>
        

        <div className="fran-eemb">
          <h1 className="fran-emb">EMBRACE THE SKILL EDGE!</h1>
          <div className="fran-features">
            <div className="max-w-sm rounded overflow-hidden shadow-lg mx-auto my-4">
              <div className="bg-white p-6 rounded-lg">
                <h2 className="text-xl font-semibold text-gray-800 mb-4">
                  "INDUSTRY-ALIGNED PROGRAMS"
                </h2>
                <p className="text-gray-600 fran-fact-desc">
                  Courses in Futuristic Technologies developed along with
                  Industry Experts and Skill Sector Councils.
                </p>
              </div>
            </div>
            <div className="max-w-sm rounded overflow-hidden shadow-lg mx-auto my-4">
              <div className="bg-white p-6 rounded-lg">
                <h2 className="text-xl font-semibold text-gray-800 mb-4">
                  "TRAINING EXCELLENCE"
                </h2>
                <p className="text-gray-600 fran-fact-desc">
                  Our Trainer Enhancement & Skilling Team (TEST) ensures your
                  instructors deliver excellence consistently.
                </p>
              </div>
            </div>
            <div className="max-w-sm rounded overflow-hidden shadow-lg mx-auto my-4">
              <div className="bg-white p-6 rounded-lg">
                <h2 className="text-xl font-semibold text-gray-800 mb-4">
                  "HERITAGE OF ACHIEVEMENT"
                </h2>
                <p className="text-gray-600 fran-fact-desc">
                  With years of expertise, the SKILL HAARA team takes pride in
                  empowering over lakhs of learners to turn their dreams into
                  reality
                </p>
              </div>
            </div>
          </div>
          <div className="fran-features">
            <div className="max-w-sm rounded overflow-hidden shadow-lg mx-auto my-4">
              <div className="bg-white p-6 rounded-lg">
                <h2 className="text-xl font-semibold text-gray-800 mb-4">
                  "DIVERSE COURSE PORTFOLIO"
                </h2>
                <p className="text-gray-600 fran-fact-desc">
                  We deliver innovative training in IT, ITeS, Data Science, AI,
                  ML, Interior & Fashion Design, Graphics & Animation,
                  Electronics, Digital Marketing, and management expertise,
                </p>
              </div>
            </div>
            <div className="max-w-sm rounded overflow-hidden shadow-lg mx-auto my-4">
              <div className="bg-white p-6 rounded-lg">
                <h2 className="text-xl font-semibold text-gray-800 mb-4">
                  "RECOGNIZED SKILL AUTHORITY"
                </h2>
                <p className="text-gray-600 fran-fact-desc">
                  Proud Accredited Partner of NSDC, NCVET, and NASSCOM
                  FutureSkills Prime – Empowering Learners with
                  Industry-Recognized Certifications.
                </p>
              </div>
            </div>
            <div className="max-w-sm rounded overflow-hidden shadow-lg mx-auto my-4">
              <div className="bg-white p-6 rounded-lg">
                <h2 className="text-xl font-semibold text-gray-800 mb-4">
                  "RECOGNIZED SKILL AUTHORITY"
                </h2>
                <p className="text-gray-600 fran-fact-desc">
                  Flexible Training Options: In-Centre, Online & Hybrid Models.
                  Genuine Industry Partnerships for Placements and Internships,
                  Ensuring Real-World
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="fran-tri">
          <h1 className="fran-rev">
            "Revolutionizing Skills Education with 100+ Courses Across 10+
            Industries!"
          </h1>

          <h1 className="fran-exp">
            Explore Possibilities – Take the First Step!
          </h1>

          <form onSubmit={handleSubmit} id="fr-form">
            <div className="fran-ff">
              <div className="fran-sub-ff">
                <input
                  type="text"
                  name="name"
                  placeholder="Name:"
                  className="fran-inp"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="text"
                  name="mobileNumber"
                  placeholder="Mobile Number:"
                  className="fran-inp"
                  value={formData.mobileNumber}
                  onChange={handleInputChange}
                  required
                  pattern="\d{10}" // Ensures exactly 10 digits
                  title="Please enter a valid 10-digit mobile number"
                />

                <input
                  type="email"
                  name="email"
                  placeholder="Email ID:"
                  className="fran-inp"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="fran-sub-ff">
                <input
                  type="text"
                  name="investment"
                  placeholder="15-25 Lakh / Above 25 Lakh"
                  className="fran-inp"
                  value={formData.investment}
                  onChange={handleInputChange}
                  required
                />
                <select
                  name="state"
                  className="fran-inp"
                  value={formData.state}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select State:</option>
                  <option value="kerala">Kerala</option>
                </select>
                <select
                  name="city"
                  className="fran-inp"
                  value={formData.city}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select City:</option>
                  <option value="Alappuzha">Alappuzha</option>
                  <option value="Ernakulam">Ernakulam</option>
                  <option value="Idukki">Idukki</option>
                  <option value="Kannur">Kannur</option>
                  <option value="Kasaragod">Kasaragod</option>
                  <option value="Kollam">Kollam</option>
                  <option value="Kottayam">Kottayam</option>
                  <option value="Kozhikode">Kozhikode</option>
                  <option value="Malappuram">Malappuram</option>
                  <option value="Palakkad">Palakkad</option>
                  <option value="Pathanamthitta">Pathanamthitta</option>
                  <option value="Thiruvananthapuram">Thiruvananthapuram</option>
                  <option value="Thrissur">Thrissur</option>
                  <option value="Wayanad">Wayanad</option>
                </select>
              </div>
              <div className="fran-sub-ff">
                <div className="captcha-container">
                  <ReCAPTCHA
                    sitekey="6LezOHEqAAAAAGocpY5W4qGBeaKwLAIYw9OfFc6m"
                    onChange={handleCaptchaChange}
                  />
                </div>
                <div>
                  <button className="fran-btn" disabled={!captchaVerified}>
                    APPLY
                  </button>
                </div>
              </div>
            </div>
          </form>
          {/* {formSubmitted && <p className="success-message">Form submitted successfully!</p>} */}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>

        <Partner />
        <div className="fran-hhol">
          <h1 className="fran-hol">Holistic Franchise Support</h1>
          <p className="fran-skill">
            At Skill Haara, we stand by you at every stage, providing all the
            resources and guidance needed to ensure your success
          </p>
          <div className="fran-features">
            <div>
              <div className="max-w-sm rounded overflow-hidden shadow-lg mx-auto my-4">
                <div className="bg-white p-6 rounded-lg">
                  <h2 className="text-xl font-semibold text-gray-800 mb-4 ">
                    Optimal Location Guidance
                  </h2>
                  <p className="text-gray-600 fran-fact-desc">
                    We assist you in selecting the best location to maximize
                    your centre's potential.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="max-w-sm rounded overflow-hidden shadow-lg mx-auto my-4">
                <div className="bg-white p-6 rounded-lg">
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">
                    Efficient Space Planning
                  </h2>
                  <p className="text-gray-600 fran-fact-desc">
                    Make the most of your space with our professional planning
                    solutions.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="max-w-sm rounded overflow-hidden shadow-lg mx-auto my-4">
                <div className="bg-white p-6 rounded-lg">
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">
                    Centre Branding & Design
                  </h2>
                  <p className="text-gray-600 fran-fact-desc">
                    We create impactful in-centre branding & design for a
                    welcoming,functional environment.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="fran-features">
            <div>
              <div className="max-w-sm rounded overflow-hidden shadow-lg mx-auto my-4">
                <div className="bg-white p-6 rounded-lg">
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">
                    Technology Setup
                  </h2>
                  <p className="text-gray-600 fran-fact-desc">
                    We assist you in selecting the ideal hardware, software to
                    ensure smooth and efficient operations.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="max-w-sm rounded overflow-hidden shadow-lg mx-auto my-4">
                <div className="bg-white p-6 rounded-lg">
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">
                    Design Lab
                  </h2>
                  <p className="text-gray-600 fran-fact-desc">
                    We assist you establish customized design labs that align
                    with courses, fostering an engaging - productive learning
                    atmosphere
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="max-w-sm rounded overflow-hidden shadow-lg mx-auto my-4">
                <div className="bg-white p-6 rounded-lg">
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">
                    Marketing Edge
                  </h2>
                  <p className="text-gray-600 fran-fact-desc">
                    Elevate your visibility with large-scale lead generation
                    activities,renowned design contests & innovative local
                    marketing strategies.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="fran-features">
            <div>
              <div className="max-w-sm rounded overflow-hidden shadow-lg mx-auto my-4">
                <div className="bg-white p-6 rounded-lg">
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">
                    Tech. Mastery
                  </h2>
                  <p className="text-gray-600 fran-fact-desc">
                    Rely on our TES Team for technical excellence with TTT and
                    TCP for top-tier course delivery and certification.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="max-w-sm rounded overflow-hidden shadow-lg mx-auto my-4">
                <div className="bg-white p-6 rounded-lg">
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">
                    Sales Mastery
                  </h2>
                  <p className="text-gray-600 fran-fact-desc">
                    Transform your sales and counselling teams into top
                    performers with advanced skills, expertly guided by our BD
                    team.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="max-w-sm rounded overflow-hidden shadow-lg mx-auto my-4">
                <div className="bg-white p-6 rounded-lg">
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">
                    Genuine Placement Assistance
                  </h2>
                  <p className="text-gray-600 fran-fact-desc">
                    We assist real career opportunities for your students
                    through our strong industry connections & placement support.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      <div className="ready-main">
        <h1 className="ready-head">Ready to Lead?</h1>
        <div className="ready-sub">
          <p className="ready-para">
            Join the Skill Haara family and drive the future of skill training
          </p>
          
        </div>
        <a href="#fr-form">
            <button className="ready-btn">Enquire Now</button>
          </a>
      </div>
      <Why />

      {/* Modal for submission message */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <button onClick={closeModal}>Close</button>
            <p>{submissionMessage}</p>
          </div>
        </div>
      )}
    </>
  );
}

export default Franchise;
