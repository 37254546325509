import React from 'react'
import mes from "../assets/images/Skill Haara  MESC Banner.png"
function mesc() {
  return (
    <div>
      <div>
        <img src={mes} alt="" />
      </div>
      <div>
        <h1 className='mesc-head'>About MESC</h1>
        <p className='mesc-para'>Media and Entertainment Skills Council is a non-profit organization that was formed in 2012. It operates under the guidelines of NSDC (National Skills Development Corporation) which is a financial supporter of MESC while FICCI (Federation of Indian Chambers of Commerce and Industry) is the promoter of MESC. The council is registered under the Societies Registration Act, 1860. The major objective of the MESC is to generate industry-ready skilled manpower, vocalization of secondary and higher secondary educational levels and also to enhance employability options for the youth through demand-driven competency-based modular courses. MESC plays an important role not only in opening doors for the youth but the long-term focus is on the revolution of M&E Education and Industry. The idea is to fill the gaps among the educated and employable to bring forth a reduction in the dropout rate in the secondary level of education.</p>
      </div>
      <div className='sub-mesc'>
        <h1 className='sub-mesc-head'>The Prime Purpose of MESC</h1>
       <ul className='sub-mesc-ul'>
        <li>To conduct skill gap study and develop occupational standards</li>
        <li>To initiate skills cataloguing for Media and Entertainment Industry.</li>
        <li>To determine skills/competency standards and qualifications and getting them noted as per NSQF.</li>
        <li>To create skills development plan with industry stakeholders, government agencies and NSDC to setup Labour Market Information System (LMIS). To ensure the standardization of affiliation, accreditation and assessment process aligned with NSQF guidelines as determined by NSQC.</li>
        <li>To identify and create of certified trainers and assessors across the Country</li>
        <li>To promote Academies of Excellence.</li>
        <li>To look into the specific skilling needs of ST/SC, differently abled and other minorities</li>
        <li>To plan and facilitate the execution of the Training of Trainers Program along with NSDC and states</li>
        <li>MESC also makes certain that the people getting training and skilling in accordance with the norms laid down are assured of employment at decent wages.</li>
       </ul>
      </div>
      <div>
        <h1 className='mesc-head-third'>Unlock Your Creative Potential: A Journey Towards <br /> Success with Skill India Certificate!</h1>
        <p className='mesc-third-on'>The Indian government established the “Skill India Mission” to improve the workforce’s abilities in a variety of industries. Empowering people with appropriate skills and giving them the training, they need to fulfil the changing needs of the labour market is the main objective of this mission. It seeks to close the skills gap between what industries need and what the workforce possesses. This program also aims to boost young job prospects and encourage economic growth.</p>
        <br />
        <p className='mesc-third-on'>
        This is where the Media & Entertainment Skill Council is working towards creating a skilled workforce in the M&E sector with its Skill India Certificate. MESC’s Skill India Certification is a valuable asset for everyone in the Media and entertainment sector. One of MESC’s objectives is educating the public about the value of skill development in the M&E sector, which is crucial for staying up to date with the rapidly changing trends and technologies in this fast-paced field.
        </p>
        <br />
        <p className='mesc-third-one'>MESC facilitates the creation of a skilled workforce for the Media and Entertainment (M&E) industry by:</p>
        <ul className='mesc-third-one'>
            <li>Defining Clear Skill Standards: Setting industry-specific skill and competency standards.</li>
            <li>Promoting High-Quality Training: Collaborating with training providers to deliver effective programs.</li>
            <li>Providing Skill India Certification: Offering recognized credentials for M&E professionals.</li>
            <li>Advocating Skills Development: Raising awareness about the importance of skill development in the M&E sector.</li>
            <li>These efforts ensure that the M&E industry can meet its workforce demands and stay competitive.</li>
        </ul>

      </div>
      <div>
        <h1 className='mesc-head-third'>The Benefits of Skill India Certificate for Professional Growth</h1>
        <ul  className='mesc-third-two'>
            <li>Govt recognized certificate</li>
            <li>Recognition of Skills</li>
            <li>Highlight Professional development</li>
            <li>Streamlining the Job search</li>
            <li>Enhance employability opportunities</li>
            <li>Advancing Career Trajectory</li>
            <li>Ease in education loan</li>
        </ul>
      </div>
      
    </div>
  )
}

export default mesc
