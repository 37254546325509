import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../App.css";
import "../assets/styles/style.css";
import logo from "../assets/images/logo.png";

function Header() {
  const [isMobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [selectedCity, setSelectedCity] = useState("");
  const navigate = useNavigate();

  const toggleMobileMenu = () => {
    setMobileMenuVisible(!isMobileMenuVisible);
  };

  const handleCityChange = (event) => {
    const city = event.target.value;
    setSelectedCity(city);
    if (city === "Thrissur") {
      navigate("/thrissur");
    } else if (city === "Thiruvananthapuram") {
      navigate("/thiruvananthapuram");
    } else if (city === "Aluva") {
      navigate("/aluva");
    }
  };

  return (
    <nav className="bg-gray-900">
     <div className="mx-auto max-w-7xl px-8 sm:px-10 lg:px-0">

        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <div className="flex items-center justify-center h-16">

            <Link to="/">
              <img className="h-8 w-auto" src={logo} alt="Logo" />
            </Link>
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex space-x-6">
            <Link to="/" className="text-white hover:text-gray-300 text-sm">
              HOME
            </Link>
            <Link
              to="/programs"
              className="text-white hover:text-gray-300 text-sm"
            >
              PROGRAMS
            </Link>
            <Link
              to="/aboutus"
              className="text-white hover:text-gray-300 text-sm"
            >
              ABOUT US
            </Link>
            <Link
              to="/contactus"
              className="text-white hover:text-gray-300 text-sm"
            >
              CONTACT US
            </Link>
            <Link
              to="/certificate"
              className="text-white hover:text-gray-300 text-sm"
            >
              CERTIFICATE VERIFICATION
            </Link>
            <Link
              to="/franchise"
              className="text-white hover:text-gray-300 text-sm"
            >
              FRANCHISE
            </Link>
            <Link
              to="/brochure"
              className="text-white hover:text-gray-300 text-sm"
            >
              BROCHURE
            </Link>

            {/* Location Dropdown */}
            <select
  className="bg-gray-900 text-white text-sm border-transparent rounded px-2 py-1 cursor-pointer focus:outline-none focus:ring-0 -mt-1"
  value={selectedCity}
  onChange={handleCityChange}
>
              <option value="">LOCATE US</option>
              <option value="Thiruvananthapuram">Thiruvananthapuram</option>
              <option value="Thrissur">Thrissur</option>
              <option value="Aluva">Aluva (Kochi)</option>
            </select>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              onClick={toggleMobileMenu}
              className="text-white hover:bg-gray-700 p-2 rounded focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
              >
                {isMobileMenuVisible ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-16 6h16"
                  />
                )}
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu (Dropdown) */}
      {isMobileMenuVisible && (
        <div className="md:hidden bg-gray-800 text-white absolute top-16 left-0 w-full z-50">
          <div className="flex flex-col items-center space-y-2 py-3">
            <Link to="/" className="hover:text-gray-300">
              HOME
            </Link>
            <Link to="/programs" className="hover:text-gray-300">
              PROGRAMS
            </Link>
            <Link to="/aboutus" className="hover:text-gray-300">
              ABOUT US
            </Link>
            <Link to="/contactus" className="hover:text-gray-300">
              CONTACT US
            </Link>
            <Link to="/certificate" className="hover:text-gray-300">
              CERTIFICATE VERIFICATION
            </Link>
            <Link to="/franchise" className="hover:text-gray-300">
              FRANCHISE
            </Link>
            <Link to="/brochure" className="hover:text-gray-300">
              BROCHURE
            </Link>

            {/* Mobile Location Dropdown */}
            <select
              className="bg-gray-900 text-white text-sm border border-gray-700 rounded px-2 py-1 cursor-pointer focus:ring-2 focus:ring-gray-500"
              value={selectedCity}
              onChange={handleCityChange}
            >
              <option value="">LOCATE US</option>
              <option value="Thiruvananthapuram">Thiruvananthapuram</option>
              <option value="Thrissur">Thrissur</option>
              <option value="Aluva">Aluva (Kochi)</option>
            </select>
          </div>
        </div>
      )}
    </nav>
  );
}

export default Header;
