import React, { useState } from "react";
import banaluva from "../assets/images/1_20250110_104205_0000.png";
import Why from "../components/Why"
import TopTechnologies from './TopTechnologies';
import Trending from './Trending';
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

function Aluva() {
  const [formData, setFormData] = useState({
    fullName: "",
    mobileNumber: "",
    email: "",
    state: "",
    city: "",
    center: "",
    message: "",
  });

  const [captchaToken, setCaptchaToken] = useState(null);
  const [errors, setErrors] = useState({});
  const [submissionMessage, setSubmissionMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const validate = () => {
    let errors = {};
    if (!formData.fullName.trim()) {
      errors.fullName = "Full Name is required";
    }
    if (!/^\d{10}$/.test(formData.mobileNumber)) {
      errors.mobileNumber = "Mobile Number should be 10 digits";
    }
    if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Invalid email format";
    }
    if (!formData.state) {
      errors.state = "State is required";
    }
    if (!formData.city) {
      errors.city = "City is required";
    }
    if (!formData.center) {
      errors.center = "Center is required";
    }
    if (!formData.message.trim()) {
      errors.message = "Message is required";
    }
    if (!captchaToken) {
      errors.captcha = "Please complete the CAPTCHA";
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const response = await axios.post(
          "https://skillhaara.com/api/user/course-enquiry",
          { ...formData, captchaToken }
        );

        if (response.status === 201) {
          setSubmissionMessage("Form submitted successfully!");
          setFormData({
            fullName: "",
            mobileNumber: "",
            email: "",
            state: "",
            city: "",
            center: "",
            message: "",
          });
          setCaptchaToken(null);
          setShowModal(true); // Show the modal on success
        }
      } catch (error) {
        setSubmissionMessage("Error occurred, couldn't submit the form.");
        console.error("There was an error!", error);

        if (error.response) {
          setErrors({
            server: error.response.data.error || "Server error occurred",
          });
        }
      }
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setSubmissionMessage("");
  };
  return (
    <div>
     <div >
        <img src={banaluva} alt="Scholarship Banner" />
      </div>
      <div className="ind-ff">
      <div className="sub-indi">
        <h1 className="ind-head">About SkillHaara Aluva (Kochi)</h1>
        <p className="ind-para">
          SkillHaara Thiruvananthapuram is a premier software training institute
          renowned for offering industry-focused IT courses and professional
          development programs. Located in the heart of Kerala's capital city,
          the institute is dedicated to empowering students and professionals
          with cutting-edge technical skills and practical knowledge. SkillHaara
          specializes in delivering training in areas like software development,
          data science, web development, digital marketing, and more, tailored
          to meet the demands of the evolving tech industry. With experienced
          trainers, hands-on projects, and placement assistance, the institute
          serves as a gateway to thriving careers in technology. Its commitment
          to quality education and a learner-centric approach has made it a
          trusted name in skill enhancement and career growth.
        </p>
        <p className="ind-para">
          SkillHaara Thiruvananthapuram is a premier software training institute
          renowned for offering industry-focused IT courses and professional
          development programs. Located in the heart of Kerala's capital city,
          the institute is dedicated to empowering students and professionals
          with cutting-edge technical skills and practical knowledge. SkillHaara
          specializes in delivering training in areas like software development,
          data science, web development, digital marketing, and more, tailored
          to meet the demands of the evolving tech industry. With experienced
          trainers, hands-on projects, and placement assistance, the institute
          serves as a gateway to thriving careers in technology. Its commitment
          to quality education and a learner-centric approach has made it a
          trusted name in skill enhancement and career growth.
        </p>
        <p className="ind-para">
          SkillHaara Thiruvananthapuram is a premier software training institute
          renowned for offering industry-focused IT courses and professional
          development programs. Located in the heart of Kerala's capital city,
          the institute is dedicated to empowering students and professionals
          with cutting-edge technical skills and practical knowledge. SkillHaara
          specializes in delivering training in areas like software development,
          data science, web development, digital marketing, and more, tailored
          to meet the demands of the evolving tech industry. With experienced
          trainers, hands-on projects, and placement assistance, the institute
          serves as a gateway to thriving careers in technology. Its commitment
          to quality education and a learner-centric approach has made it a
          trusted name in skill enhancement and career growth.
        </p>
      </div>
      <div>
        <form className="responsive-ind-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              name="fullName"
              placeholder="Full Name"
              value={formData.fullName}
              onChange={handleChange}
              required
            />
            {errors.fullName && <p className="error">{errors.fullName}</p>}
            <input
              type="text"
              name="mobileNumber"
              placeholder="Mobile Number"
              value={formData.mobileNumber}
              onChange={handleChange}
              required
            />
            {errors.mobileNumber && (
              <small className="error" style={{ color: "white" }}>
                {errors.mobileNumber}
              </small>
            )}
          </div>
          <div className="form-group">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <p className="error">{errors.email}</p>}
            <select
              name="center"
              value={formData.center}
              onChange={handleChange}
              required
            >
              <option value="">Select Center</option>

              <option value="Aluva (kochi)">Aluva (Kochi)</option>
            </select>
            {errors.center && <p className="error">{errors.center}</p>}
          </div>
          <div className="form-group">
            <select
              name="state"
              value={formData.state}
              onChange={handleChange}
              required
            >
              <option value="">Select State</option>
              <option value="Kerala">Kerala</option>
            </select>
            {errors.state && <p className="error">{errors.state}</p>}
            <select
              name="city"
              value={formData.city}
              onChange={handleChange}
              required
            >
              <option value="">Select City</option>
              <option value="Alappuzha">Alappuzha</option>
              <option value="Ernakulam">Ernakulam</option>
              <option value="Idukki">Idukki</option>
              <option value="Kannur">Kannur</option>
              <option value="Kasaragod">Kasaragod</option>
              <option value="Kollam">Kollam</option>
              <option value="Kottayam">Kottayam</option>
              <option value="Kozhikode">Kozhikode</option>
              <option value="Malappuram">Malappuram</option>
              <option value="Palakkad">Palakkad</option>
              <option value="Pathanamthitta">Pathanamthitta</option>
              <option value="Thiruvananthapuram">Thiruvananthapuram</option>
              <option value="Thrissur">Thrissur</option>
              <option value="Wayanad">Wayanad</option>
            </select>
            {errors.city && <p className="error">{errors.city}</p>}
          </div>
          <div className="form-group">
            <textarea
              name="message"
              placeholder="Message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            {errors.message && <p className="error">{errors.message}</p>}
          </div>

          <div className="captcha-container">
            <ReCAPTCHA
              sitekey="6LezOHEqAAAAAGocpY5W4qGBeaKwLAIYw9OfFc6m"
              onChange={handleCaptchaChange}
            />
            {errors.captcha && <p className="error">{errors.captcha}</p>}
          </div>

          <button type="submit" className="submit-btn">
            Submit
          </button>
          {/* {submissionMessage && <strong className="sub-msg">{submissionMessage}</strong>} */}
          {errors.server && (
            <p className="error" style={{ color: "white" }}>
              {errors.server}
            </p>
          )}
        </form>
        {showModal && (
          <div className="modal">
            <div className="modal-content">
              <button onClick={closeModal}>Close</button>
              <p>{submissionMessage}</p>
            </div>
          </div>
        )}
      </div>
  
      </div>

          <TopTechnologies />
          <Trending />
          <div style={{ maxWidth: '100%', overflowX: 'hidden' }}>
    <iframe
      title="Google Map - Skill Haara Aluva"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3927.855477332413!2d76.3508282!3d10.110913700000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080f26397fba8f%3A0x482ccbb49b012154!2sSkill%20Haara%20Aluva!5e0!3m2!1sen!2sin!4v1736482622560!5m2!1sen!2sin"
      width="100%"
      height="300"
      style={{ border: 0 }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>
          <Why/>
    </div>
  )
}

export default Aluva
