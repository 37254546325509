import React from 'react'
import Why from "../components/Why"
function Infrabrouscher() {
  return (
   <>
    <div style={{ border: '1px solid lightgray', width: '100%', height: '700px' }}>
      <iframe
        allowFullScreen
        scrolling="no"
        className="fp-iframe"
        src="https://heyzine.com/flip-book/a25bce9b3c.html"
        style={{ border: 'none', width: '100%', height: '100%' }}
        title="FlipBook"
      ></iframe>
    </div>
   <Why/>
   </>
  )
}

export default Infrabrouscher
