import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import banner1 from '../assets/images/title.jpg';
import banner2 from '../assets/images/bann1.jpg';
import banner3 from '../assets/images/bann2.jpg';
import banner4 from '../assets/images/bann3.jpg';
import banner5 from "../assets/images/bann4.jpg";

import banner7 from "../assets/images/Skill Haara  MESC Banner.png"
import "../assets/styles/style.css";

function Baner() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const banners = [banner1, banner2, banner3, banner4, banner5,banner7];
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [banners.length]);

  const handleBannerClick = (index) => {
    // if (index === 0) {
    //   navigate('/skillfest2025'); // Navigate to /skillfest2024 when banner6 is clicked
    // }
    // else
    if(index === 5){
      navigate('/mesc'); // Navigate to /mesc when banner7 is clicked
    }
  };

  return (
    <div className="carousel-wrapper">
      <div
        className="carousel-container"
        style={{
          transform: `translateX(${-currentIndex * 100}%)`,
          transition: 'transform 0.5s ease-in-out',
          display: 'flex',
        }}
      >
        {banners.map((banner, index) => (
          <img
            key={index}
            src={banner}
            alt={`Banner ${index + 1}`}
            className="carousel-image"
            onClick={() => handleBannerClick(index)} // Add click handler
            style={{ cursor: 'pointer' }} // Change cursor to pointer for clickable banners
          />
        ))}
      </div>
      
      {/* Dots */}
      <div className="dots-container">
        {banners.map((_, index) => (
          <span
            key={index}
            className={`dot ${currentIndex === index ? 'active' : ''}`}
            onClick={() => setCurrentIndex(index)}
          ></span>
        ))}
      </div>
    </div>
  );
}

export default Baner;
