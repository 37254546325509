import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PageNotFound from '../components/PageNotFound';
import Technologies from '../components/Technologies';
import Aboutus from '../components/Aboutus';
import Contactus from '../components/Contactus';
import Certificate from '../components/Certificate';
import ViewEach from '../components/ViewEach';
import EDA from '../components/EDA';
import AdminLogin from '../components/AdminLogin';
import Admin from '../components/Admin';
import DemandSingle from '../components/DemandSingle';
import Programs from '../components/Programs';
import SkillFest from '../components/SkillFest';
import Mesc from '../components/mesc';
import Thrissur from '../components/Thrissur';
import Thiruvananthapuram from '../components/Thiruvananthapuram';
import Aluva from "../components/Aluva"
import Softwarebrouscher from '../components/Softwarebrouscher';
import Fasionbrouscher from '../components/Fasionbrouscher';
import Infrabrouscher from '../components/Infrabrouscher';
import Aibrouscher from '../components/Aibrouscher';
import Digitalbrouscher from '../components/Digitalbrouscher';
import Franchise from '../components/Franchise';
import Broschures from '../components/Broschures';
import EdaBroucher from '../components/EdaBroucher';
import Graphics from '../components/Graphics';


function HomePage() {
  return (
    <div className="homepage-container">
      <Header />
      <main className="homepage-content">
        <Routes>
          <Route path="/" element={<Technologies />} />
          <Route path="aboutus" element={<Aboutus />} />
          <Route path="contactus/*" element={<Contactus />} />
          <Route path="certificate" element={<Certificate />} />
          <Route path="adminlogin" element={<AdminLogin />} />
          <Route path="admin*17/*" element={<Admin />} />
          <Route path="eda" element={<EDA />} />
          <Route path="programs" element={<Programs />} />
          <Route path="/vieweach/:techId" element={<ViewEach />} />
          <Route path="/demandsingle/:demandId" element={<DemandSingle />} />
          <Route path="skillfest2025" element={<SkillFest />} />
          <Route path="/mesc" element={<Mesc/>}></Route>
          <Route path='/thrissur' element={<Thrissur/>}/>
          <Route path='/thiruvananthapuram' element={<Thiruvananthapuram/>}/>
          <Route path='/aluva' element={<Aluva/>}/>
          <Route path="*" element={<PageNotFound />} />



          <Route path='/software' element={<Softwarebrouscher/>}></Route>
          <Route path='/fashion' element={<Fasionbrouscher/>}></Route>
          <Route path='/infrastructure' element={<Infrabrouscher/>}></Route>
          <Route path='/ai' element={<Aibrouscher/>}></Route>
          <Route path='/dm' element={<Digitalbrouscher/>}></Route>
          <Route path="/electronics" element={<EdaBroucher/>}></Route>
          <Route path='/graphics' element={<Graphics/>}></Route>
          
          <Route path='/franchise' element={<Franchise/>}></Route>

          <Route path='/brochure' element={<Broschures/>}></Route>
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default HomePage;
