import React from "react";

function Partner() {
  return (
    <div>
      <h1 className="fran-part">
        Partner with Skill Haara for Guaranteed Success!
      </h1>
      <div className="part-ff">
            <div>
            <div className="fra-fact-one">WHAT YOU NEED TO GET STARTED?</div>
            <div className="fra-two">
                <ul>
                <li className="fra-li">
                    1800- 2000 SQ.FT. OF COMMERCIAL PROPERTY.
                </li>
                <li className="fra-li">CAPACITY TO INVEST AROUND 10-20 LAKHS</li>
                <li className="fra-li">
                    PASSION FOR THE EDUCATION SECTOR AND COMMITMENT TO DELIVER
                    QUALITY TRAININGS
                </li>
                </ul>
            </div>
            <div className="fra-fact-onee">
                WHAT WOULD YOU EARN THROUGH SKILL HAARA ?
            </div>
            <div className="fra-two">
                <ul>
                <li className="fra-li">
                    EXPECT A STRONG ROI, BETWEEN 50 – 70%, DEPENDING ON YOUR CITY
                    AND OPERATIONAL EFFICIENCY
                </li>
                <li className="fra-li">
                    RECOUP YOUR INVESTMENT BETWEEN 6 – 12 MONTHS
                </li>
                <li className="fra-li">
                    EARN A RESPECTED PLACE IN YOUR TOWN AND COMMUNITY BY MAKING A
                    MEANINGFUL IMPACT IN LAKHS OF CAREERS
                </li>
                </ul>
            </div>
            </div>
            {/* <div className="fra-three">
            <h1 className="fra-sec-head">FRANCHISEE SUPPORT </h1>
            <div>
                <ul className="fra-sec-ul">
                <li className="fra-sec-li">
                    <b>Kickstart with a 3-Day Induction:</b> Join us in
                    Thiruvananthapuram, Kerala, for an immersive franchise training
                    experience.
                </li>
                <li className="fra-sec-li">
                    <b>Operational Excellence:</b> Access all our dedicated Support
                    Team and Materials to keep your franchisee running smoothly and
                    successfully.{" "}
                </li>
                <li className="fra-sec-li">
                    <b>Sales & Marketing Support:</b> Encash our continuous guidance
                    in Marketing, Sales and Center Management.{" "}
                </li>
                <li className="fra-sec-li">
                    <b>Business Plans & Opportunity Matrix:</b> We act as Key to
                    unlock the closed opportunities and thereby business and
                    profits.{" "}
                </li>
                <li className="fra-sec-li">
                    <b>Demand Creation Activities:</b> Dive into the realms of
                    innovation and creativity with our diverse competition
                    categories which supports your business development.{" "}
                </li>
                <li className="fra-sec-li">
                    <b>Growth Focus from Day 1:</b> Confirms high returns on
                    investment (ROI), along with consistent income and long-term
                    profitability.
                </li>
                </ul>
            </div>
            </div> */}
      </div>
    </div>
  );
}

export default Partner;
