import React from 'react';
import Why from "../components/Why";

function Graphics() {
  return (
   
      <>
      <div style={{ border: '1px solid lightgray', width: '100%', height: '700px' }}>
        <iframe
          allowFullScreen
          scrolling="no"
         className="fp-iframe"
          src="https://heyzine.com/flip-book/ea1e2e58d1.html"
          title="FlipBook"
          style={{ border: "none", width: "100%", height: "100%" }}
        ></iframe>
      </div>
      <Why />
      </>
    
  );
}

export default Graphics;
